import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import Button from '@mui/material/Button';
import DataTable from './Components/DataTable.js';
import ButtonAppBar from './Components/ButtonAppBar';
import Toolbar from '@mui/material/Toolbar';

function App() {
  return (
    <>
    <ButtonAppBar />
    {/* This Toolbar makes it so things aren't hidden behind the ButtonAppBar, don't know why... */}
    <Toolbar />
    <div className="App">
      <DataTable />
    </div>
    </>
  );
}

export default App;
